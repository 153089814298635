import {useCallback, memo} from "react";
import { Geographies } from "react-simple-maps";
import {AnimatedGeography} from "./AnimatedGeography";

export const StaticMap = memo(function StaticMap({
                                                     geographyData,
                                                     visitedCountries,
                                                     onClickCountry,
                                                     onHoverCountry,
                                                     onLeaveCountry
                                                 }) {
    const isVisited = useCallback((geoId) => {
        return visitedCountries.includes(geoId);
    }, [visitedCountries]);

    return (
        <Geographies geography={geographyData}>
            {({ geographies }) => (
                geographies.map((geo) => (
                    <AnimatedGeography
                        key={geo.rsmKey}
                        geo={geo}
                        isVisited={isVisited(geo.id)}
                        handleClick={onClickCountry}
                        onHover={onHoverCountry}
                        onHoverLeave={onLeaveCountry}
                    />
                ))
            )}
        </Geographies>
    );
});