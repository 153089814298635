import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";

/**
 * axios instance
 */

const baseURL = "http://localhost:5000";
const paramsSerializer = function (params) {
  return JSON.stringify(params);
};
const params =  {paramsSerializer}
if (isDevelopment) {
    params.baseURL = baseURL;
}
let instance = axios.create(params);

// request header
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const fetchClient = instance;
