import { fetchClient } from "./fetchClient";
const API_USER_ROUTE = "/api/v1/users";

export async function getVisitedCountries(userId, cb) {
  fetchClient.get(`${API_USER_ROUTE}/${userId}`).then((response) => {
    if (response?.data?.visitedCountries) {
      cb(response?.data?.visitedCountries);
    } else {
      //TODO good error
      console.log("Error getting visitedCountries");
      cb([]);
    }
  });
}

export async function visitCountry(user, countryId, cb) {
  //TODO change to new api

  fetchClient.patch(`${API_USER_ROUTE}/${user._id}`, user).then((response) => {
    if (response?.data?.visitedCountries) {
      cb(response?.data?.visitedCountries);
    } else {
      //TODO good error
      console.log("Error getting visitedCountries");
      cb([]);
    }
  });
}
