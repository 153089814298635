import axios from "axios";
import { fetchClient } from "./fetchClient";

const API_USER_ROUTE = "/api/v1";

const isDevelopment = process.env.NODE_ENV === "development";

const baseURL = isDevelopment? "http://localhost:5000": "";

export function login(email, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseURL}${API_USER_ROUTE}/signin`, {
        email,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        resolve(response.data);
      })
      .catch((error) => {
        console.log("error login", error);
        reject(error);
      });
  });
}

export async function signup(email, password, homeCountry) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseURL}${API_USER_ROUTE}/signup`, {
        email,
        password,
        homeCountry,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        resolve(response.data);
      })
      .catch((error) => {
        console.log("error signup", error);
        reject(error);
      });
  });
}

//     .then((response) => {
//       if (response.data.token) {
//         localStorage.setItem("token", response.data.token);
//       }
//       return response.data;
//     });
// }

export const logout = () => {
  localStorage.removeItem("token");
  return Promise.resolve();
};

export const getUser = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return Promise.resolve(null);
  }
  return new Promise((resolve, reject) => {
    fetchClient
      .get(`${API_USER_ROUTE}/users/me`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
