import {
  Box,
  Flex,
  Button,
  Stack,
  Center,
  Input,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
  MenuSeparator,
} from "@/components/ui/menu";
import { Avatar } from "@/components/ui/avatar";
import { InputGroup } from "@/components/ui/input-group";
import { ColorModeButton } from "@/components/ui/color-mode";
import {
  AiOutlineEllipsis,
  AiOutlineSearch,
  AiOutlineClose,
} from "react-icons/ai";
import { useAuth } from "../context/AuthContext";
import { useModalContext } from "../context/ModalContext";

const MenuFlexBlock = ({ children, ...props }) => {
  return (
    <Flex
      alignItems="center"
      gap={4}
      bg={"menu-bg/90"}
      backdropFilter="blur(10px)"
      paddingX={4}
      paddingY={1}
      borderRadius={31}
      {...props}
    >
      {children}
    </Flex>
  );
};

const LeftNavBar = () => {
  const favoriteMaps = ["World", "Europe", "USA"];
  return (
    <Flex alignItems="center" gap={4}>
      <MenuFlexBlock>
        <IconButton
          size="sm"
          aria-label="Home page"
          icon={
            <img
              src={process.env.PUBLIC_URL + "/icons/favicon.svg"}
              alt="Logo"
            />
          }
          variant="ghost"
        />
        <Stack direction="row" spacing={4} alignItems="center">
          {favoriteMaps.map((map, index) => (
            <Button key={index} variant="ghost" color="brand.200" hover>
              {map}
            </Button>
          ))}
          <Button variant="ghost" color="brand.200">
            <AiOutlineEllipsis />
          </Button>
        </Stack>
      </MenuFlexBlock>
      <MenuFlexBlock flexGrow={1} justifyContent="center">
        <HStack gap="10" width="full">
          <InputGroup
            flex="1"
            startElement={<AiOutlineSearch />}
            endElement={<AiOutlineClose />}
          >
            <Input placeholder="Search" variant="ghost" bg={"menu-bg/0"} />
          </InputGroup>
        </HStack>
      </MenuFlexBlock>
    </Flex>
  );
};

const RightNavBar = ({ user, onLogout }) => {
  const { openModal } = useModalContext();
  return (
    <MenuFlexBlock alignItems="center" gap={4}>
      <Button variant="ghost">Journal</Button>
      <Button variant="ghost">Share</Button>
      <ColorModeButton />
      {user && (
        <MenuRoot>
          <MenuTrigger asChild>
            <Button variant="link" cursor="pointer" minW={0}>
              <Avatar
                src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04"
                shape="full"
                size="sm"
              />
            </Button>
          </MenuTrigger>
          <MenuContent>
            <Center>
              <p>Profile</p>
            </Center>
            <MenuSeparator />
            <MenuItem>Profile</MenuItem>
            <MenuItem>Settings</MenuItem>
            <MenuItem onClick={onLogout}>Sign Out</MenuItem>
          </MenuContent>
        </MenuRoot>
      )}
      {!user && (
        <Button variant="link" onClick={openModal}>
          Log In
        </Button>
      )}
    </MenuFlexBlock>
  );
};

export default function NavBar() {
  const { user, logout } = useAuth();

  return (
    <Box
      as="header"
      position="absolute"
      top="20px"
      w="100%"
      px="4"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      zIndex="50"
    >
      <LeftNavBar />
      <RightNavBar user={user} onLogout={() => logout()} />
    </Box>
  );
}

// export default function NavBar({ isLoggedIn, onLogout }) {
//   // const { isOpen, onOpen, onClose } = useDisclosure();
//   return (
//     <>
//       {/*<Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>*/}
//       <Box
//         as="header"
//         position="absolute" // Чтобы он был над картой
//         top="0"
//         w="100%" // Растянуть на всю ширину
//         bg="transparent" // Прозрачный фон
//         color="white"
//         zIndex="50" // Убедиться, что он выше карты
//         // bg="blackAlpha.700"
//         // color="white"
//         h="60px"
//         display="flex"
//         alignItems="center"
//         px="4"
//         justifyContent="space-between"
//       >
//         <Flex h={16} alignItems="center" justifyContent="space-between">
//           <Box color="gray.800" _dark={{ color: "white" }}>
//             Logo
//           </Box>
//
//           <Flex alignItems="center">
//             <Stack direction="row" spacing={7}>
//               <ColorModeButton />
//               {!isLoggedIn ? (
//                 <Box>
//                   <Button
//                     size="sm"
//                     rounded="full"
//                     variant="link"
//                     cursor="pointer"
//                     minW={0}
//                     color="gray.800"
//                     _dark={{ color: "white" }}
//                   >
//                     Log In
//                   </Button>
//                 </Box>
//               ) : (
//                 <MenuRoot>
//                   <MenuTrigger asChild>
//                     <Button
//                       size="sm"
//                       rounded="full"
//                       variant="link"
//                       cursor="pointer"
//                       minW={0}
//                     >
//                       <Avatar
//                         src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04"
//                         shape="full"
//                         size="lg"
//                       />
//                     </Button>
//                   </MenuTrigger>
//                   <MenuContent alignItems="center">
//                     <br />
//                     <Center>
//                       {/*<Avatar*/}
//                       {/* size={"2xl"}*/}
//                       {/*  src={*/}
//                       {/*    "https://avatars.dicebear.com/api/male/username.svg"*/}
//                       {/*  }*/}
//                       {/*/>*/}
//                     </Center>
//                     <br />
//                     <Center>
//                       <p>Username</p>
//                     </Center>
//                     <br />
//                     <MenuSeparator />
//                     <MenuItem>Your Servers</MenuItem>
//                     <MenuItem>Account Settings</MenuItem>
//                     <MenuItem onClick={onLogout}>Logout</MenuItem>
//                   </MenuContent>
//                 </MenuRoot>
//                 // <Button
//                 //   size="sm"
//                 //   rounded="full"
//                 //   variant="link"
//                 //   cursor="pointer"
//                 //   minW={0}
//                 // >
//                 //   <Avatar
//                 //     size="sm"
//                 //     src="https://avatars.dicebear.com/api/male/username.svg"
//                 //   />
//                 // </Button>
//               )}
//             </Stack>
//           </Flex>
//         </Flex>
//       </Box>
//     </>
//   );
// }
