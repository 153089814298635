import { createContext, useState, useContext, useEffect } from "react";
import * as auth from "../API/auth";

export const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);
  // const [fetched, setFetched] = useState(false);

  const signup = (email, password, homeCountry) => {
    return auth
      .signup(email, password, homeCountry)
      .then((data) => {
          setUser(data.user);
          return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const login = (email, password) => {
    return auth
      .login(email, password)
      .then((data) => {
        setUser(data.user);
          return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const logout = async () => {
    setUser(null);
    await auth.logout();
  };

  const getUser = () => {
    auth
      .getUser()
      .then((newUser) => {
        setUser(newUser);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setUser(null);
      });
  };

  // if (!fetched) {
  //   getUser().then(() => setFetched(true));
  // }

  // if (!fetched) {
  //   return <Loading />;
  // }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        signup,
        login,
        logout,
        getUser,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export { AuthContextProvider };
