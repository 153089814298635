import { IconButton, Stack } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { FaApple, FaFacebook } from "react-icons/fa";

export default function SocialMediaLogin() {
  return (
    <Stack direction="row" spacing={2}>
      <IconButton
        aria-label="Log in with Google"
        variant="outline"
        onClick={() => alert("Log in with Google")}
      >
        <FcGoogle />
      </IconButton>
      <IconButton
        aria-label="Log in with Facebook"
        variant="outline"
        color="blue.500"
        onClick={() => alert("Log in with Facebook")}
      >
        <FaFacebook />
      </IconButton>
      <IconButton
        aria-label="Log in with Apple"
        variant="outline"
        onClick={() => alert("Log in with Apple")}
      >
        <FaApple />
      </IconButton>
    </Stack>
  );
}
