import {useState, useRef, useEffect, memo, useMemo, useCallback} from "react";
import gsap from "gsap";
import { useZoomPanContext } from "react-simple-maps";
import { Geography, useMapContext } from "react-simple-maps";
import { useToken } from "@chakra-ui/react";
import { getRandomColor, getRadiusFromClick } from "../utils/geoUtils";

export const AnimatedGeography = memo(function AnimatedGeography({
  geo,
  isVisited,
  handleClick,
  // setTooltipContent,
  onHover,
  onHoverLeave
}) {
  const [circleCoords, setCircleCoords] = useState({ cx: 0, cy: 0 });
  const [isAnimatedFinished, setIsAnimatedFinished] = useState(true);
  const [visitedColor, setVisitedColor] = useState(getRandomColor());
  const ctx = useZoomPanContext();
  const { projection } = useMapContext();
  const circleRef = useRef(null);
  // const theme = useTheme();
  const countryBorderColor = useToken("colors", "country-border");
  const countryBgColor = useToken("colors", "country-bg");

  const geographyStyle = useMemo(() => ({
    default: { outline: "none" },
    hover: { outline: "none" },
    pressed: { outline: "none" }
  }), []);


  const getSvgCoordinates = (event) => {
    const svg = event.target.ownerSVGElement || event.target;
    const pt = svg.createSVGPoint();
    // console.log("ctx", ctx);
    pt.x = event.clientX;
    pt.y = event.clientY;

    let svgCoords = pt.matrixTransform(svg.getScreenCTM().inverse());

    svgCoords.x = (svgCoords.x - ctx.x) / ctx.k;
    svgCoords.y = (svgCoords.y - ctx.y) / ctx.k;
    return svgCoords;
  };


  const handleMouseEnter = useCallback(() => {
    if (onHover) {
      onHover(geo);
    }
  }, [geo, onHover]);

  const handleMouseLeave = useCallback(() => {
    if (onHoverLeave) {
      onHoverLeave(geo);
    }
  }, [geo, onHoverLeave]);

  const handleGeoClick = useCallback((event) => {
    if (!isVisited) {
      setIsAnimatedFinished(false);
      const svgCoords = getSvgCoordinates(event);
      setCircleCoords({ cx: svgCoords.x, cy: svgCoords.y });
      setVisitedColor(getRandomColor());
      handleClick(geo);
    }
  }, [geo, isVisited, handleClick]);



  // const handleGeoClick = (event) => {
  //   if (!isVisited) {
  //     setIsAnimatedFinished(false);
  //     const svgCoords = getSvgCoordinates(event);
  //     setCircleCoords({ cx: svgCoords.x, cy: svgCoords.y });
  //     setVisitedColor(getRandomColor());
  //     handleClick(geo);
  //   }
  // };

  useEffect(() => {
    if (circleCoords.cx !== 0 && circleCoords.cy !== 0) {
      const optimalRadius = getRadiusFromClick(circleCoords, geo, projection);
      // console.log("optimalRadius", optimalRadius);
      gsap.fromTo(
        circleRef.current,
        { attr: { cx: circleCoords.cx, cy: circleCoords.cy, r: 1 } },
        {
          attr: { r: optimalRadius },
          duration: 2,
          ease: "power2.out",
          onComplete: () => {
            setIsAnimatedFinished(true);
          },
        },
      );
    }
  }, [circleCoords, geo, projection]);

  return (
    <g>
      <defs>
        {/*Animation circle mask*/}
        <mask id={`mask-${geo.id}`}>
          <circle ref={circleRef} cx={0} cy={0} r="0" fill="white" />
        </mask>
      </defs>

      <Geography
        data-tooltip-id="my-tooltip"
        data-tooltip-content={geo.properties.name}
        key={geo.rsmKey}
        geography={geo}
        style={geographyStyle}
        fill={
          (isAnimatedFinished && isVisited)
              ? visitedColor
              : countryBgColor
        }
        fillOpacity={"1"}
        stroke={countryBorderColor}
        strokeWidth={"0.5"}
        pointerEvents={"all"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleGeoClick}
      />

      {/* Masked country animation on click */}
      <path
        d={geo.svgPath}
        fill={visitedColor}
        visibility={
          circleCoords.cx !== 0 && (!isAnimatedFinished || !isVisited) ? "visible" : "hidden"
        }
        stroke={countryBorderColor}
        strokeWidth={"0.5"}
        mask={`url(#mask-${geo.id})`}
        pointerEvents="none"
        // style={{
        //   cursor: "pointer",
        //   outline: "none",
        // }}
      />
    </g>
  );
}
// , (prevProps, nextProps) => {
//   for (const key in prevProps) {
//     if (prevProps[key] !== nextProps[key]) {
//       console.log(`Prop ${key} changed`);
//       return false;
//     }
//   }
//   return true;
// }
);
