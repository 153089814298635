import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Input,
  Link,
  Stack,
  Text,
  Heading,
  Box,
  Fieldset,
  createListCollection,
} from "@chakra-ui/react";
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from "@/components/ui/dialog";
import { InputGroup } from "@/components/ui/input-group";
import { Field } from "@/components/ui/field";
import {
  NativeSelectField,
  NativeSelectRoot,
} from "@/components/ui/native-select";

import { LuMail, LuLockKeyhole, LuEyeOff, LuEye } from "react-icons/lu";

import { useAuth } from "../../context/AuthContext";
import { useModalContext } from "../../context/ModalContext";
import SocialMediaLogin from "./SocialMediaLogin";
import { rules } from "../utils/authUtils";
import CountrySelector from "./CountrySelector";
import geoData from "../../topojson/test.json";

export default function SignUp({ changeMode }) {
  const { signup } = useAuth();
  const { closeModal } = useModalContext();

  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      repeatPassword: "",
      country: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  async function onSubmit(values) {
    if (values.password !== values.repeatPassword) {
      setError("repeatPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }

    try {
      const data = await signup(
        values.email,
        values.password,
        values.country[0]
      );
      if (data?.user) {
        closeModal();
      } else {
        setError("root", {
          type: "server",
          message: "Something went wrong. Please try again.",
        });
      }
    } catch (err) {
      console.log(err);
      setError("root", {
        type: "server",
        message: "Something went wrong. Please try again.",
      });
    }
  }

  return (
    <DialogContent p={6} maxW="400px">
      <DialogHeader>
        <DialogTitle size="md" textAlign="center">
          Sign Up
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => e.stopPropagation()}
        >
          <Fieldset.Root
            size="lg"
            invalid={
              !!(
                errors.email ||
                errors.password ||
                errors.repeatPassword ||
                errors.root
              )
            }
          >
            <Fieldset.Content>
              <Stack direction="column" spacing={2}>
                <Field required invalid={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    rules={rules.email}
                    render={({ field }) => (
                      <InputGroup
                        flex="1"
                        width="full"
                        startElement={<LuMail />}
                      >
                        <Input
                          type="email"
                          variant="outline"
                          placeholder="Email"
                          {...field}
                        />
                      </InputGroup>
                    )}
                  />
                  <Fieldset.ErrorText>
                    {errors.email?.message}
                  </Fieldset.ErrorText>
                </Field>

                <Field required invalid={!!errors.password}>
                  <Controller
                    name="password"
                    control={control}
                    rules={rules.password}
                    render={({ field }) => (
                      <InputGroup
                        flex="1"
                        width="full"
                        startElement={<LuLockKeyhole />}
                        endElement={
                          <ShowPasswordButton
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                          />
                        }
                      >
                        <Input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                        />
                      </InputGroup>
                    )}
                  />
                  <Fieldset.ErrorText>
                    {errors.password?.message}
                  </Fieldset.ErrorText>
                </Field>

                <Field required invalid={!!errors.repeatPassword}>
                  <Controller
                    name="repeatPassword"
                    control={control}
                    rules={{
                      required: "Please confirm your password",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    }}
                    render={({ field }) => (
                      <InputGroup
                        flex="1"
                        width="full"
                        startElement={<LuLockKeyhole />}
                        endElement={
                          <ShowPasswordButton
                            showPassword={showRepeatPassword}
                            setShowPassword={setShowRepeatPassword}
                          />
                        }
                      >
                        <Input
                          placeholder="Repeat Password"
                          type={showRepeatPassword ? "text" : "password"}
                          {...field}
                        />
                      </InputGroup>
                    )}
                  />
                  <Fieldset.ErrorText>
                    {errors.repeatPassword?.message}
                  </Fieldset.ErrorText>
                </Field>

                <Field invalid={!!errors.country}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => <CountrySelector {...field} />}
                  />
                  <Fieldset.ErrorText>
                    {errors.country?.message}
                  </Fieldset.ErrorText>
                </Field>

                <Fieldset.ErrorText>{errors.root?.message}</Fieldset.ErrorText>
              </Stack>
            </Fieldset.Content>

            <Button type="submit" variant="solid" isLoading={isSubmitting}>
              Sign Up
            </Button>
          </Fieldset.Root>
        </form>
      </DialogBody>

      <DialogFooter flexDir="column">
        <Text fontSize="xs" mb={2}>
          Or sign up with
        </Text>

        <SocialMediaLogin />

        <Box mt={4}>
          <Link fontSize="sm" color="blue.500" onClick={changeMode}>
            {"Already have an account? Log in"}
          </Link>
        </Box>
        <DialogCloseTrigger />
      </DialogFooter>
    </DialogContent>
  );
}

export function ShowPasswordButton({ showPassword, setShowPassword }) {
  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={() => setShowPassword(!showPassword)}
      py={0}
    >
      {showPassword ? <LuEye /> : <LuEyeOff />}
    </Button>
  );
}
