import {useEffect, useState} from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import { DialogRoot } from "@/components/ui/dialog";

export default function AuthDialog({ open, setOpen }) {
  const [isLogin, setIsLogin] = useState(true);

  const changeMode = () => {
    setIsLogin(!isLogin);
  };

  const handleOpenChange = (e) => {
      setOpen(e.open);
  };

  // useEffect(() => {
  //   console.log("isLogin changed to", isLogin )
  // }, [isLogin]);

  return (
    <DialogRoot open={open} onOpenChange={handleOpenChange} closeOnInteractOutside={false}>
      {isLogin ? (
        <Login changeMode={changeMode} />
      ) : (
        <SignUp changeMode={changeMode} />
      )}
    </DialogRoot>
  );
}
