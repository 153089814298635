import { system } from "@/components/ui/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ColorModeProvider } from "@/components/ui/color-mode";

// import { ColorModeProvider } from "./color-mode";

export function Provider({ children }) {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider>{children}</ColorModeProvider>
      {/*<ColorModeProvider {...props} />*/}
      {/*  { children }*/}
    </ChakraProvider>
  );
}
