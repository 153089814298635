import { createSystem, defaultConfig, defineConfig } from "@chakra-ui/react";

const config = defineConfig({
  theme: {
    tokens: {
      colors: {
        brand: {
          50: { value: "#edf2fa" },
          100: { value: "#d2d8e0" },
          200: { value: "#b5bec8" },
          300: { value: "#A0AEC0" },
          400: { value: "#7b8b9d" },
          500: { value: "#627183" },
          600: { value: "#4c5867" },
          700: { value: "#36414E" },
          800: { value: "#212830" },
          900: { value: "#040e13" },
        },
      },
    },
    semanticTokens: {
      colors: {
        gray: {
          subtle: {
            value: { _light: "{colors.gray.50}", _dark: "#212830" }, // Custom dark subtle background
          },
        },
        bg: {
          DEFAULT: {
            value: { _light: "{colors.white}", _dark: "#212830" }, // Custom dark background
          },
          subtle: {
            value: { _light: "{colors.gray.50}", _dark: "#36414E" }, // Custom dark subtle background
          },
          muted: {
            value: { _light: "{colors.gray.100}", _dark: "#36414E" }, // Custom dark muted background
          },
        },
        fg: {
          DEFAULT: {
            value: { _light: "{colors.black}", _dark: "{colors.white}" }, // Custom dark text color
          },
          muted: {
            value: { _light: "{colors.gray.600}", _dark: "#A0AEC0" }, // Custom dark muted text
          },
          subtle: {
            value: { _light: "{colors.gray.400}", _dark: "#D1D1D6" }, // Custom dark muted text
          },
        },
        border: {
          DEFAULT: {
            value: { _light: "{colors.gray.200}", _dark: "#798094" }, // Custom dark border
          },
        },
        "country-bg": {
          value: { _light: "{colors.gray.200}", _dark: "#ffffff" },

          // value: { _light: "{colors.gray.200}", _dark: "white" },
        },
        "country-border": {
          value: { _light: "{colors.gray.400}", _dark: "#959CAA" },
        },
        "country-border-hover": {
          value: { _light: "{colors.gray.600}", _dark: "black" },
        },
        "menu-bg": {
          value: { _light: "{colors.gray.200}", _dark: "{colors.brand.700}" },
        },
        // "menu-fg": {
        //   value: { _light: "{colors.gray.200}", _dark: "{colors.brand.30}" },
        // },
      },
    },
  },
});

export const system = createSystem(defaultConfig, config);
