import React from "react";
import ReactDOM from "react-dom/client"; // Новый импорт для createRoot
import "./index.css";
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root")); // Используем createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
