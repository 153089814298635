import {useEffect, useState} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import MapContent from "./components/map/MapContent";
import { Provider } from "./components/ui/provider";
import { AuthContextProvider } from "./context/AuthContext";
import { ModalContext } from "./context/ModalContext";

// import PrivateRoute from "./components/auth/PrivateRoute";

import { defaultSystem } from "@chakra-ui/react";
import AuthDialog from "./components/auth/AuthDialog";

export function App() {
  // const { user } = useAuth();

  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("Modal open");
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    console.log("Modal closed");
    setModalOpen(false);
  };

  const modalHandlers = {
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  };

  // useEffect(() => {
  //   console.log("modalOpen changed", modalOpen);
  // }, [modalOpen]);

  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const handleLogin = (userData) => {
  //   console.log("userData", userData);
  //   // setIsLoggedIn(true);
  //   // setIsDialogOpen(false);
  //   // localStorage.setItem("user", JSON.stringify(userData));
  // };

  // const handleLogout = () => {
  //   logout();
  // };

  return (
    <Provider value={defaultSystem}>
      <Router>
        <AuthContextProvider>
          <ModalContext.Provider value={modalHandlers}>
            <Box h="100vh" w="100vw" display="flex" flexDirection="column">
              <NavBar />
              <Box flex="1" overflow="hidden">
                <MapContent />
              </Box>
            </Box>
            {/* Dialog Component to force login */}
            <AuthDialog open={modalOpen} setOpen={setModalOpen} />
          </ModalContext.Provider>
        </AuthContextProvider>
      </Router>
    </Provider>
  );
}
