// import { useState, useContext } from "react";
import { Box } from "@chakra-ui/react";
import MapComponent from "./MapComponent";

export default function MapContent() {
  return (
    <Box
      h="100%"
      w="100%"
      position="relative" // Позволяет позиционирование вложенных элементов
      zIndex="0" // Убедиться, что оно ниже NavBar
    >
      <MapComponent />
    </Box>
  );
}
