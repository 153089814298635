import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Input,
  Link,
  Stack,
  Text,
  Heading,
  Box,
  Fieldset,
} from "@chakra-ui/react";
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from "@/components/ui/dialog";
import { InputGroup } from "@/components/ui/input-group";
import { Field } from "@/components/ui/field";

import { LuMail, LuLockKeyhole, LuEyeOff, LuEye } from "react-icons/lu";

import { useAuth } from "../../context/AuthContext";
import { useModalContext } from "../../context/ModalContext";
import SocialMediaLogin from "./SocialMediaLogin";
import { rules } from "../utils/authUtils";

export default function Login({ changeMode }) {
  const { login } = useAuth();
  const { closeModal } = useModalContext();

  // Подключаем control и isSubmitting
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  async function onSubmit(values) {
    try {
      const data = await login(values.email, values.password);
      if (data?.user) {
        closeModal();
      } else {
        setError("root", {
          type: "server",
          message: "Login failed. Error:"+ JSON.stringify(data?.error),
        });
      }
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) {
        setError("password", {
          type: "server",
          message: "Invalid email or password",
        });
      } else {
        setError("root", {
          type: "server",
          message: "Something went wrong. Please try again.",
        });
      }
    }
  }

  return (
    <DialogContent p={6} maxW="400px">
      <DialogHeader>
        <DialogTitle size="md" textAlign="center">
            Log in
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)}  onClick={(e) => e.stopPropagation()}>
          <Fieldset.Root
            size="lg"
            invalid={!!(errors.email || errors.password || errors.root)}
          >
            <Fieldset.Content>
              <Stack direction="column" spacing={2}>
                <Field required invalid={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    rules={rules.email}
                    render={({ field }) => (
                      <InputGroup
                        flex="1"
                        width="full"
                        startElement={<LuMail />}
                      >
                        <Input
                          type="email"
                          variant="outline"
                          placeholder="Email"
                          {...field}
                        />
                      </InputGroup>
                    )}
                  />
                  <Fieldset.ErrorText>
                    {errors.email && errors.email.message}
                  </Fieldset.ErrorText>
                </Field>

                <Field
                  required
                  invalid={!!errors.password}
                  helperText={
                    <Link
                      fontSize="sm"
                      color="blue.500"
                      onClick={() => alert("in development")}
                    >
                      Forgot password?
                    </Link>
                  }
                >
                  <Controller
                    name="password"
                    control={control}
                    rules={rules.password}
                    render={({ field }) => (
                      <InputGroup
                        flex="1"
                        width="full"
                        startElement={<LuLockKeyhole />}
                        endElement={
                          <ShowPasswordButton
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                          />
                        }
                      >
                        <Input
                          // variant="outline"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                        />
                      </InputGroup>
                    )}
                  />
                  <Fieldset.ErrorText>
                    {errors.password && errors.password.message}
                  </Fieldset.ErrorText>
                </Field>
                <Fieldset.ErrorText>
                  {errors.root && errors.root.message}
                </Fieldset.ErrorText>
              </Stack>
            </Fieldset.Content>

            <Button type="submit" variant="solid" isLoading={isSubmitting}>
              Log in
            </Button>
          </Fieldset.Root>
        </form>
      </DialogBody>

      <DialogFooter flexDir="column">
        <Text fontSize="xs" mb={2}>
          Or Log in with
        </Text>

        <SocialMediaLogin />

        <Box mt={4}>
          <Link fontSize="sm" color="blue.500" onClick={changeMode}>
            {"Don't have an account? Sign Up"}
          </Link>
        </Box>
        <DialogCloseTrigger />
      </DialogFooter>
    </DialogContent>
  );
}

export function ShowPasswordButton({ showPassword, setShowPassword }) {
  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={() => setShowPassword(!showPassword)}
      py={0}
    >
      {showPassword ? <LuEye /> : <LuEyeOff />}
    </Button>
  );
}
