import { memo } from "react";
import { Geography } from "react-simple-maps";
import { useToken } from "@chakra-ui/react";

export const HoveredGeography = memo(function HoveredGeography({ geo }) {
  const countryBorderHoverColor = useToken("colors", "country-border-hover");

  return (
    <g pointerEvents="none">
      <defs>
        <filter
          id="outer-shadow"
          filterUnits="userSpaceOnUse"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
        >
          <feDropShadow
            dx="1"
            dy="1"
            stdDeviation="3"
            floodColor="#000"
            floodOpacity="0.7"
          />
        </filter>
        {/*Mask for exclude shadow inside*/}
        <mask
          id={`hover-mask-${geo.id}`}
          maskUnits="userSpaceOnUse"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
        >
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <path d={geo.svgPath} fill="black" />
        </mask>
      </defs>

      <Geography
        // className="hover-country-class"
        // key={geo.rsmKey}
        geography={geo}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        fill={"#000"}
        fillOpacity={"0"}
        stroke={countryBorderHoverColor}
        strokeWidth={"0.5"}
        pointerEvents={"none"}
        // onClick={handleGeoClick}
        style={{
          default: { outline: "none" },
          hover: {
            outline: "none",
          },
          pressed: { outline: "none" },
        }}
      />

      {/* Shadow */}
      <path
        d={geo.svgPath}
        fill="none"
        stroke="#000"
        filter="url(#outer-shadow)"
        mask={`url(#hover-mask-${geo.id})`}
        pointerEvents="none"
      />
    </g>
  );
});
