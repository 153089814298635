import {useCallback, useState, useMemo} from "react";
import { ZoomableGroup, ComposableMap } from "react-simple-maps";

// import geoData from "../../topojson/test.json";
import geoData from "../../topojson/world-highres2.topo.json";
import {HoveredGeography} from "./HoveredGeography";
import {StaticMap} from "./StaticMap";


export default function MapChart({
  visitedCountries,
  visitCountry,
}) {
  // const geoObj = useMemo(() => geoData, []);
  const [hoveredCountry, setHoveredCountry] = useState(null);

  const handleClick = useCallback((geo) => {
    const countryId = geo.id;
    if (!visitedCountries.includes(countryId)) {
      visitCountry(countryId);
    }
  }, [visitedCountries, visitCountry]);

  const handleHover = useCallback((geo) => {
    setHoveredCountry(geo);
  }, []);

  const handleHoverLeave = useCallback(() => {
    setHoveredCountry(null);
  }, []);

  const mapStyle = useMemo(() => ({ width: "100%", height: "auto", overflow: "visible" }), []);

  return (
    <ComposableMap
      projection="geoEqualEarth"
      style={mapStyle}
    >
      <ZoomableGroup>
        <StaticMap
            geographyData={geoData}
            visitedCountries={visitedCountries}
            onClickCountry={handleClick}
            onHoverCountry={handleHover}
            onLeaveCountry={handleHoverLeave}
        />
        {hoveredCountry && (
            <HoveredGeography geo={hoveredCountry} />
        )}
      </ZoomableGroup>
    </ComposableMap>
  );
}

