export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const convertLatLngToSvgCoords = (lat, lng, projection) => {
  const [x, y] = projection([lng, lat]);
  return { x, y };
};

export const getRadiusFromClick = (clickCoords, geo, projection) => {
  let maxDistance = 0;

  const calculateDistance = (lat, lng) => {
    const { x, y } = convertLatLngToSvgCoords(lat, lng, projection);
    return Math.sqrt(
      Math.pow(x - clickCoords.cx, 2) + Math.pow(y - clickCoords.cy, 2),
    );
  };

  const processCoordinates = (coordinates) => {
    coordinates.forEach((coordinate) => {
      if (Array.isArray(coordinate) && coordinate.length === 2) {
        const [lng, lat] = coordinate;
        const distance = calculateDistance(lat, lng);
        maxDistance = Math.max(maxDistance, distance);
      }
    });
  };

  if (geo?.geometry?.type === "Polygon") {
    geo.geometry.coordinates.forEach(processCoordinates);
  } else if (geo?.geometry?.type === "MultiPolygon") {
    geo.geometry.coordinates.forEach((multiPolygon) =>
      multiPolygon.forEach(processCoordinates),
    );
  } else {
    throw new Error("Unsupported geo.geometry.type");
  }

  return Math.max(maxDistance, 10); // Минимум 10 для небольших стран
};
