import React, { useMemo } from "react";
import { createListCollection } from "@chakra-ui/react";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/components/ui/select";
import topologyData from "../../topojson/world-highres2.topo.json";


// Оборачиваем в forwardRef
const CountrySelector = React.forwardRef(function CountrySelector(props, ref) {
  const { name, value, onChange, onBlur } = props;

  // Формируем список стран единожды (useMemo)
  const countriesItems = useMemo(() => {
    return topologyData.objects.default.geometries.map((geom) => ({
      label: geom.properties.name,
      value: geom.properties["iso-a2"],
    }))      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  // Создаём коллекцию для нашего Select
  const countries = useMemo(() => {
    return createListCollection({
      items: countriesItems,
    });
  }, [countriesItems]);

  return (
      <SelectRoot
          name={name}
          value={value} // expected array
          onValueChange={({ value: newValue }) => onChange(newValue)}
          onInteractOutside={onBlur}
          collection={countries}
      >
        <SelectTrigger clearable ref={ref}>
          <SelectValueText placeholder="Select your country" />
        </SelectTrigger>

        <SelectContent zIndex={1500}>
          {countries.items.map((country) => (
              <SelectItem item={country} key={country.value}>
                {country.label}
              </SelectItem>
          ))}
        </SelectContent>
      </SelectRoot>
  );
});

export default CountrySelector;
