import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import MapChart from "./MapChart";
import { useAuth } from "../../context/AuthContext";
import { getVisitedCountries, visitCountry } from "../../API/api";
import { useModalContext } from "../../context/ModalContext";

export default function MapComponent() {
  // const [selectCountry, setSelectCountry] = useState("");
  const [visitedCountries, setVisitedCountries] = useState([]);
  const { user } = useAuth();
  const { openModal } = useModalContext();

  useEffect(() => {
    // const fetchCountries = async () => {
    //   getVisitedCountries()
    //     setVisitedCountries(data.visitedCountries || []);
    //   }
    // };
    if (user) {
      getVisitedCountries(user?._id, setVisitedCountries);
    } else {
      setVisitedCountries([]);
    }
  }, [user]);

  const handleVisitCountry = async (countryID) => {

    if (user) {
      //todo change
      const newUser = {...user};
      newUser.visitedCountries = [...visitedCountries, countryID];
      await visitCountry(newUser, countryID, setVisitedCountries);
    } else {
      openModal();
    }
  };

  return (
    <div>
      <MapChart
        // setTooltipContent={setSelectCountry}
        visitedCountries={visitedCountries}
        visitCountry={handleVisitCountry}
      />
      {/*<Tooltip anchorSelect=".hover-country-class"> {selectCountry}</Tooltip>*/}
      <Tooltip
          id="my-tooltip"
          render={({ content, activeAnchor }) => (
              <span>
            {content}
          </span>
          )}
      />
    </div>
  );
}

// <ComposableMap>
//   <Geographies geography={geoObj}>
//     {({ geographies }) =>
//       geographies.map((geo) => (
//         <Geography key={geo.rsmKey} geography={geo} />
//       ))
//     }
//   </Geographies>
// </ComposableMap>
